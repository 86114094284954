export default (
	title: string,
	options: { description?: string; robots?: string; image?: string; canonical?: string; seoTitle?: string } = {},
) => {
	const config = useClientSideConfig();
	const domain = `https://www.${usePublicationInfo().domain}`;
	const compoundTitle = `${title} | ${config.verlagstitel_3}`; // overline: options.seoTitle || title | mandant

	const metaTags = [
		{ name: "description", content: options.description || config.defaultPageDescription },
		{
			name: "robots",
			content: options.robots || "index, follow, noarchive, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
		},
		{
			name: "og:image",
			content: options.image || `${domain}/static/favicons/${usePublication()}/mstile-310x310.png`,
		},
		{ name: "og:title", content: title },
		{ name: "og:description", content: options.description || config.defaultPageDescription },
	];

	// SUP-987 - Affiliate Programm - https://www.flexoffers.com/
	// Nur auf Startseite anzeigen
	if (usePublication() === "swp" && useRoute().path === "/") {
		metaTags.push({
			name: "fo-verify",
			content: "678a0c3a-b4b7-4280-adae-5d123c877759",
		});
	}

	useHead({
		title: compoundTitle,
		meta: metaTags,
		link: [
			{
				rel: "canonical",
				href: options.canonical || `${domain}${useRoute().path}`,
			},
		],
	});
};
